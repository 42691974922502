import React, { useEffect, useRef } from "react"

export default function Video(props) {
  const { src, ...restProps } = props
  const ref = useRef()

  useEffect(() => {
    if (props["data-object-fit"] && window && window.objectFitPolyfill)
      window.objectFitPolyfill(ref.current)
  }, [props])

  return (
    <video muted ref={ref} {...restProps}>
      <source src={props.src} type="video/mp4" />
    </video>
  )
}
