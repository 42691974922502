import classNames from "classnames"
import { graphql } from "gatsby"
import React from "react"

import Img from "../components/common/Img"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import VideoHero from "../components/VideoHero/VideoHero"
import {
  selectCrownStandardPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import s from "./crown-standard.module.scss"

const videoFlameGrilled = require("../../static/video/crown-standard-flamegrilled.mp4")
const videoBeef = require("../../static/video/crown-standard-beef.mp4")
const burgerImg = "/images/pages/crown-standard/burger.png"
const pattyImg = "/images/pages/crown-standard/patty.png"
const garlicImg = "/images/pages/crown-standard/garlic.png"
const tomatoImg = "/images/pages/crown-standard/tomato.png"

export default function CrownStandardPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const {
    seo,
    videoHeroText,
    flameGrilledText,
    beefText,
    vegetablesText,
    whooperText,
  } = selectCrownStandardPageContent(data)

  return (
    <Layout
      className={s.crownStandardPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <VideoHero
        className={s.hero}
        topEdgeHidden={true}
        videoUrl={videoFlameGrilled}
      >
        <h2>{videoHeroText}</h2>
      </VideoHero>
      <h2
        className={s.videoTextBlock}
        dangerouslySetInnerHTML={{ __html: flameGrilledText }}
      />
      <VideoHero className={s.hero} videoUrl={videoBeef} />
      <div className={s.descriptionWrapper}>
        <div className={classNames(s.descriptionBlock, s.row)}>
          <Img
            alt="Patty"
            className={classNames(s.image, s.pattyImage)}
            objectFit="contain"
            objectPosition="center"
            placeholderHidden
            src={pattyImg}
          />
          <h2
            className={s.textBlock}
            dangerouslySetInnerHTML={{ __html: beefText }}
          />
        </div>
        <div className={classNames(s.descriptionBlock, s.row)}>
          <Img
            alt="Garlic"
            className={classNames(s.image, s.garlicImage)}
            objectFit="contain"
            objectPosition="right"
            placeholderHidden
            src={garlicImg}
          />
          <h2
            className={s.textBlock}
            dangerouslySetInnerHTML={{ __html: vegetablesText }}
          />
          <Img
            alt="Tomato"
            className={classNames(s.image, s.tomatoImage)}
            objectFit="contain"
            objectPosition="left"
            placeholderHidden
            src={tomatoImg}
          />
        </div>
        <div className={s.burgerBlock}>
          <Img
            alt="Crown Standard"
            className={s.burger}
            hidePlaceholder
            src={burgerImg}
          />
          <h2
            className={s.textBlock}
            dangerouslySetInnerHTML={{ __html: whooperText }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CrownStandardPage($locale: String) {
    ...LayoutFragment
    ...CrownStandardPageFragment
  }
`
