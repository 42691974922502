import classNames from "classnames"
import React from "react"

import { BottomEdgeLine, TopEdgeLine } from "../common/Decorations"
import Video from "../common/Video"
import s from "./VideoHero.module.scss"

export default function VideoHero({
  children,
  className,
  topEdgeHidden,
  videoAsBackground,
  videoUrl,
}) {
  const objectFitProps = videoAsBackground && {
    dataObjectFit: "cover",
    dataObjectPosition: "center",
  }

  return (
    <div
      className={classNames(className, s.videoHero, {
        [s.videoAsBackground]: videoAsBackground,
      })}
    >
      {topEdgeHidden ? null : <TopEdgeLine />}
      <Video
        autoPlay
        className={s.video}
        loop
        muted
        playsInline
        src={videoUrl}
        {...objectFitProps}
      />
      {children ? <div className={s.content}>{children}</div> : null}
      <BottomEdgeLine />
    </div>
  )
}
